<template>
  <v-content>
    <v-container grid-list-lg fluid style="margin-bottom: 58px;">
      <v-layout wrap align-start justify-start fill-height>
        <v-flex xs12 lg12 xl12>
          <app-title title="Venta de cajas por color"> </app-title>
        </v-flex>
        <v-flex xs4 lg4 xl3>
          <v-card class="elevation-2">
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field v-model="max" label="Maximo de flores" hide-details></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field v-model="min" label="Minimo de flores" hide-details></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field v-model="mix" label="Mezcla de flores" hide-details></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field v-model="min_buy" label="Monto minimo de compras" hide-details></v-text-field>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" flat @click.native="updateInfo">Actualizar</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
        <v-flex xs8 lg8 xl9>
          <v-card class="elevation-2">
            <v-data-table
              :headers="headers"
              :items="items"
              :pagination.sync="pagination"
              :rows-per-page-items="rowsPerPage"
              class="elevation-0"
              disable-initial-sort
            >
              <template slot="items" slot-scope="props">
                <td>{{ props.item.name }}</td>
                <td>{{ props.item.price | toPrice }}</td>
                <td>{{ props.item.price_high | toPrice }}</td>
                <td>
                  <v-chip v-if="props.item.status" color="success" text-color="white" label>
                    <v-icon left>check</v-icon> Activo
                  </v-chip>
                  <v-chip v-else color="error" text-color="white" label> <v-icon left>clear</v-icon> Inactivo </v-chip>
                </td>
                <td>
                  <v-btn
                    target="_blank"
                    :to="{ name: 'productsCajaId', params: { id: props.item.id } }"
                    color="info"
                    small
                  >
                    Editar
                  </v-btn>
                  <v-btn color="error" small @click="deleteItem(props.item.id)">
                    Eliminar
                  </v-btn>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <v-btn
      target="_blank"
      fixed
      dark
      fab
      bottom
      right
      color="accent"
      :to="{ name: 'productsCajaId', params: { id: 'nuevo' } }"
    >
      <v-icon> add </v-icon>
    </v-btn>
  </v-content>
</template>

<script>
import appTitle from '../../useful/title.vue'
import { GET_COLORS_CAJA, GET_PRODUCTS_ALL, GET_COLORS_CAJA_GENERAL } from '../../../config'

export default {
  name: 'VentaDeCajasColor',
  components: { appTitle },
  data: () => ({
    max: 12,
    min: 6,
    mix: 2,
    min_buy: 0,
    product: 2094,
    productsAll: [],
    loading: true,
    items: [],
    categories: [],
    pagination: { rowsPerPage: 50 },
    rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }],
    productsFilter: {
      on: true,
      off: true,
      hidden: false
    },
    searchText: '',
    headers: [
      {
        text: 'Nombre',
        align: 'left',
        sortable: true,
        value: 'name'
      },
      {
        text: 'Precio',
        align: 'left',
        sortable: true,
        value: 'type'
      },
      {
        text: 'Precio Alta demanda',
        align: 'left',
        sortable: true,
        value: 'type'
      },
      {
        text: 'Estado',
        align: 'left',
        sortable: true,
        value: 'active'
      },
      {
        text: '',
        align: 'left',
        sortable: false
      }
    ],
    status: false,
    name: '',
    idEdit: null,
    dialogEdit: false,
    type: null,
    description: null,
    orders: null
  }),
  computed: {
    user() {
      return this.$store.getters.getUser
    }
  },
  mounted() {
    this.getInfo()
    this.searchProducts()
  },
  methods: {
    async getInfo() {
      const res = await this.$http.get(GET_COLORS_CAJA)
      this.items = res.data.colors
      this.product = res.data.configs_.product_color_id
      this.min = res.data.configs_.product_color_min
      this.max = res.data.configs_.product_color_max
      this.mix = res.data.configs_.product_color_mix
      this.min_buy = res.data.configs_.product_color_min_buy
    },
    async sendDialog() {
      try {
        const params = {
          name: this.name,
          status: this.status,
          description: this.description,
          orders: this.orders,
          type: this.type
        }
        if (this.idEdit) {
          await this.$http.put(`${GET_COLORS_CAJA}/${this.idEdit}`, params)
        } else {
          await this.$http.post(`${GET_COLORS_CAJA}`, params)
        }
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Actualizado'
        })
        this.dialogEdit = false
        this.getInfo()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async searchProducts() {
      try {
        const res = await this.$http.get(GET_PRODUCTS_ALL, {
          params: {
            on: true,
            off: true,
            hidden: false
          }
        })
        this.productsAll = res.data
        this.loading = false
      } catch (error) {
        this.$store.dispatch('changeSnack', { active: true, text: 'Ocurrio un error, intente nuevamente' })
      }
    },
    async updateInfo() {
      try {
        const res = await this.$http.put(GET_COLORS_CAJA_GENERAL, {
          min: this.min,
          min_buy: this.min_buy,
          max: this.max,
          mix: this.mix,
          product: this.product
        })
        this.productsAll = res.data
      } catch (error) {
        this.$store.dispatch('changeSnack', { active: true, text: 'Ocurrio un error, intente nuevamente' })
      }
    },
    deleteItem(id) {
      const res = confirm('¿Eliminar color?')
      if (res) {
        this.deleteItemConfirm(id)
        return
      }
    },
    async deleteItemConfirm(id) {
      try {
        await this.$http.delete(`${GET_COLORS_CAJA}/${id}`)
        this.getInfo()
      } catch (error) {
        this.$store.dispatch('changeSnack', { active: true, text: 'Ocurrio un error, intente nuevamente' })
      }
    },
    openAtributo(i) {
      if (i) {
        this.idEdit = i.id
        this.name = i.name
        this.status = i.status
        this.description = i.description
        this.orders = i.orders
        this.type = i.type
      }
      this.dialogEdit = true
    },
    openValues(id) {
      this.$router.push({ name: 'productsFeaturesValues', params: { id } })
    },
    typeShipping(type) {
      if (type === 1) {
        return 'Semanal'
      }
      if (type === 2) {
        return 'Quincenal'
      }
      if (type === 3) {
        return 'Mensual'
      }
    }
  }
}
</script>
